import * as React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Header from "../components/header"
import NextPage from "../components/nextPage"
import Insights from "../components/insights"
import ScrollingTextBanner from "../components/scrollingTextBanner"
import { graphql } from "gatsby"
import Testimonials from "../components/testimonials"
import LineDivider from "../components/LineDivider"
import Spacer from "../components/spacer"


class InformationPage extends React.Component {
	render() {

		var data = this.props.data.allDatoCmsInformation.edges[0].node;
		const insights = this.props.data.allDatoCmsInsight.nodes;
		const testimonials = this.props.data.allDatoCmsTestimonial.nodes;

		return (
			<Layout className="pth">
				<SEO title="Insights on Siera Group" />
				<Header page='Insights ' />
				<ScrollingTextBanner title={data.heroTitle} image={data.image?.gatsbyImageData} caption={data.heroContent} />
				<LineDivider first={false} />
				<Insights insights={insights} />
				<LineDivider className='m-hide ' last='m-h-50  ' />
				<Testimonials testimonials={testimonials} />
				<Spacer className='m-h-50' />
				<NextPage title={data.nextTitle} link={data.nextLink} image={data.nextImage} />
			</Layout>
		)
	}
}

export default InformationPage


export const query = graphql`
  query Information {
	allDatoCmsInsight {
		nodes {
			image {
				gatsbyImageData
				url
				focalPoint {
        			x
      			}
			}
			title
			subtitle
			url
			name
		}
  	}
    allDatoCmsInformation {
	    edges {
	      node {
	        heroTitle
	        heroContent
	        image {
	          gatsbyImageData
			  url
			  
	        }
	        nextLink
	        nextTitle
	        nextImage {
	          gatsbyImageData
			  url
	        }
	      }
	    }
	  }
	  allDatoCmsTestimonial(limit:1) {
		nodes {
			title
			role
			subtitle
			paragraph
			videoUrl
			image {
				gatsbyImageData
				url
			}
		}
	}
  }
`
